import React from "react";
import {Container, Header, Menu, Rail, Segment} from "semantic-ui-react";
import {Link} from "@reach/router";
import styles from "./foundation.module.css";
import cx from 'classnames';
import DesktopContainer from "../../container/DesktopContainer";


const Policy = () => (
    <Segment className={styles.segment}>
        <DesktopContainer>
            <Rail position='right' className={styles.rail}>
                <Segment raised className={styles.menuSegment}>
                    <Menu secondary vertical className={styles.menu}>
                        <Link to="/stiftung/">
                            <Menu.Item className={styles.menuItem}>Stiftung</Menu.Item>
                        </Link>
                        <Link to="/stiftung/grundsaetze/">
                            <Menu.Item className={cx(styles.menuItem, styles.active)}>Grundsätze</Menu.Item>
                        </Link>
                        <Link to="/stiftung/principles/">
                            <Menu.Item className={styles.menuItem}>Prinzipien</Menu.Item>
                        </Link>
                        <Link to="/stiftung/transparenz/">
                            <Menu.Item className={styles.menuItem}>Transparenz</Menu.Item>
                        </Link>
                        <Link to="/stiftung/anlagerichtlinien/">
                            <Menu.Item className={styles.menuItem}>Anlagerichtlinien</Menu.Item>
                        </Link>
                    </Menu>
                </Segment>
            </Rail>
        </DesktopContainer>

        <Container className={styles.container}>
            <p>Die Deutsche Stiftung Kinderdermatologie orientiert sich an den "Grundsätzen Guter Stiftungspraxis", die der Bundesverband Deutscher Stiftungen beschlossen hat. Die wichtigsten Punkte lesen Sie hier:</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                Präambel
            </Header>
            <p>Die Deutsche Stiftung verfolgt von der Stifterin bestimmte gemeinwohlorientierte Zwecke, welche in ihrer Satzung verankert sind und durch die Erträge aus dem Stiftungsvermögen erfüllt werden sollen. Die DSK hat ein Vermögen, das ihr grundsätzlich auf Dauer und ungeschmälert zur Verfügung stehen soll. Die Stiftungsorgane und der Schirmherr gewährleisten eine ordnungsgemäße Erfüllung des jeweiligen Stiftungszwecks.
            </p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                I. Für die handelnden Personen der Deutschen Stiftung Kinderdermatologie
            </Header>
            <p>Schirmherr, Stiftungsorgane, Stiftungsverwalter und Stiftungsmitarbeiter der DSK orientieren sich im Rahmen der gesetzlichen Vorgaben des Gemeinnützigkeits- und Stiftungsrechts bei ihrer Tätigkeit insbesondere an folgenden Grundsätzen:</p>
            <p>Sie verstehen sich als Treuhänder des im Stiftungsgeschäft und in der Satzung formulierten Stifterwillens.</p>
            <p>Sie sind der Satzung verpflichtet und verwirklichen den Stiftungszweck nach bestem Wissen und Gewissen.</p>
            <p>Das in ihre Obhut gegebene Vermögen ist in seiner nachhaltigen Ertragsfähigkeit zu erhalten. Das Rechnungswesen bildet die wirtschaftliche Lage der Stiftung zeitnah, vollständig und sachlich richtig ab. Die Verwaltungsausgaben bewegen sich in einem angemessenen Rahmen.</p>
            <p>Sie anerkennen Transparenz als Ausdruck der Verantwortung von Stiftungen gegenüber der Gesellschaft und als ein Mittel zur Vertrauensbildung. Sie stellen daher der Öffentlichkeit in geeigneter Weise die wesentlichen inhaltlichen und wirtschaftlichen Informationen über die Stiftung (insbesondere über den Stiftungszweck, die Zweckerreichung im jeweils abgelaufenen Jahr, die Förderkriterien und die Organmitglieder) zur Verfügung. Sie veröffentlichen ihre Bewilligungsbedingungen und setzen, soweit geboten, unabhängige Gutachter oder Juroren ein. Gesetzliche Auskunftspflichten werden rasch und vollständig erfüllt.
            </p>
            <p>Die Mitglieder der Stiftungsorgane handeln informiert, integer und verantwortungsvoll. Ehrenamtlich tätige Organmitglieder sind trotz ihrer übrigen Verpflichtungen bereit, die erforderliche Zeit und Sorgfalt für die Stiftungsarbeit zur Verfügung zu stellen. Mitglieder von Kontroll- und Beratungsgremien sind grundsätzlich unabhängig von den für die operative Tätigkeit verantwortlichen Organen und werden von diesen umfassend und wahrheitsgemäß informiert.</p>
            <p>Die Stiftungsorgane sorgen für die regelmäßige Überprüfung der Wirksamkeit der Stiftungsprogramme, vor allem im Hinblick auf die Verwirklichung des Satzungszwecks, die Effizienz des Mitteleinsatzes und im Hinblick auf das Verhalten gegenüber Fördersuchenden sowie der Öffentlichkeit; sie fördern entsprechendes Verhalten ihrer Mitarbeiter.</p>
            <p>Die Stiftungsorgane von fördernden Stiftungen betrachten Fördersuchende als unverzichtbare Partner zur Verwirklichung der Stiftungszwecke. Anfragen sollten zeitnah beantwortet werden; über den Fortgang der Antragsbearbeitung sollte informiert werden.</p>
            <p>Die Stiftungsorgane fördern den Erfahrungsaustausch und die Zusammenarbeit mit anderen Stiftungen.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                II. Zur Vermeidung von Interessenkonflikten
            </Header>
            <p>Für den Schirmherrn, Mitglieder der Stiftungsorgane, der Kontroll- und Beratungsgremien und für Stiftungsmitarbeiter gilt, dass sich niemand bei seinen Entscheidungen von eigennützigen Interessen leiten lässt. Insbesondere beachten sie folgende Grundsätze:</p>
            <p>Sie legen die Anhaltspunkte für einen Interessenkonflikt im Einzelfall unaufgefordert offen und verzichten von sich aus auf eine Beteiligung am Entscheidungsprozess, wenn dieser ihnen oder einer nahestehenden Person einen unmittelbaren Vorteil oder Nachteil bringen kann. Auch persönliche oder familiäre Beziehungen zu den Fördersuchenden und zu Dienstleistungsunternehmen werden offen kommuniziert.</p>
            <p>Sie verzichten auf vermögenswerte Vorteile, die ihnen von interessierter Seite verschafft werden. Dies gilt auch dann, wenn die Verknüpfung von Vorteil und Gegenleistung nicht unmittelbar oder erst zukünftig zu erwarten ist.
            </p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                Zu Ihrer weiteren Information
            </Header>
            <p>Die Deutsche Stiftung Kinderdermatologie ist Mitglied im <a href="https://www.stiftungen.org/startseite.html" className={'primary'}>Bundesverband Deutscher Stiftungen</a>. Diese unabhängige Dachorganisation steht seit mehr als 60 Jahren für die Förderung des gemeinwohlorientierten Stiftungswesens. Sie vertritt 6.000 Stiftungen in Deutschland und setzt sich dafür ein, dass die Rahmenbedingungen des stifterischen Handelns nachhaltig verbessert werden.</p>
            <p>Auf den Internetseiten des Bundesverbandes Deutscher Stiftungen finden Sie auch weitere Informationen über die Grundsätze Guter Stiftungspraxis.</p>
        </Container>

    </Segment>
);

export default Policy;